import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


export default function ButtonTop({ ButtonText }) {
  return (
    <>
      {/* <Row>
        <Col>
          <p className="text-right">
            <Button
              className="btn-primary"
              size="md"
              onClick={ () => window.scrollTo({
                top: 0,
                behavior: 'smooth'
              }) }
            >
              Back to Top&nbsp;&nbsp;<i class="fas fa-arrow-up"></i>
            </Button>
          </p>
        </Col>
      </Row> */}
    </>
  )
}
