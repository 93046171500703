import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


export default function FocusQuestion({ focusQuestionBodyText }) {
  return (
    <Row>
      <Col>
        <Card className="cardHeader mb-4">
          <Card.Header>
            <h2 className="headerFontBlue">
              FOCUS QUESTION:
            </h2>
          </Card.Header>
          <Card.Body className="cardBodyBackground">
            <p className="lead">
              { focusQuestionBodyText }
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
