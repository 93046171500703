import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


export default function Introduction({ paragraphOne, paragraphTwo, paragraphThree, paragraphFour, paragraphFive, paragraphSix, paragraphSeven, paragraphEight, paragraphNine, paragraphTen}) {
  return (
    <Row>
      <Col>
        <Card className="mb-4">
          <Card.Header className="cardHeader">
            <h1 className="headerFontBlue">Introduction</h1>
          </Card.Header>
          <Card.Body className="cardBodyBackground">
            <div>
              {
                paragraphOne
                ?
                <>{ paragraphOne }</>
                :
                <></>
              }
              {
                paragraphTwo
                ?
                <>{ paragraphTwo }</>
                :
                <></>
              }
              {
                paragraphThree
                ?
                <>{ paragraphThree }</>
                :
                <></>
              }
              {
                paragraphFour
                ?
                <>{ paragraphFour }</>
                :
                <></>
              }
              {
                paragraphFive
                ?
                <>{ paragraphFive }</>
                :
                <></>
              }
              {
                paragraphSix
                ?
                <>{ paragraphSix }</>
                :
                <></>
              }
              {
                paragraphSeven
                ?
                <>{ paragraphSeven }</>
                :
                <></>
              }
              {
                paragraphEight
                ?
                <>{ paragraphEight }</>
                :
                <></>
              }
              {
                paragraphNine
                ?
                <>{ paragraphNine }</>
                :
                <></>
              }
              {
                paragraphTen
                ?
                <>{ paragraphTen }</>
                :
                <></>
              }
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
