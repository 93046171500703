import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit2Icon from '../../images/icons/unit_02_icon.svg'
import traffic29 from '../../images/student-pages/unit-2/29_traffic.jpg'
import idea30 from '../../images/student-pages/unit-2/30_bright_idea.png'
import candleExp31 from '../../images/student-pages/unit-2/31_candle_experiment.png'
import co2Mauna26 from '../../images/student-pages/unit-2/26_co2_mauna_2004.gif'
import boxModel32 from '../../images/student-pages/unit-2/32_boxmodel_carboncycle.png'
import earthActive33 from '../../images/student-pages/unit-2/33_earth_active_carbon.png'
import co2cont34 from '../../images/student-pages/unit-2/34_co2_concentrations_1000_years.png'
import modernBox35 from '../../images/student-pages/unit-2/35_modern_box_model.png'
import swamp36 from '../../images/student-pages/unit-2/36_swamp.jpg'
import peat37 from '../../images/student-pages/unit-2/37_peat.jpg'
import methane38 from '../../images/student-pages/unit-2/38_methane.png'
import octane39 from '../../images/student-pages/unit-2/39_octane.png'
import paraffin40 from '../../images/student-pages/unit-2/40_paraffin.png'
import lightOn41 from '../../images/student-pages/unit-2/41_light_on.jpg'
import energySupply42 from '../../images/student-pages/unit-2/42_energy_supply_for_us.gif'


const CarbonCycling24 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 2.4 Carbon Cycling" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeTwo location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'2.4 Carbon Cycling'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit2Icon }
                iconAlt="Unit 2 icon"
                studentPageHeader="Unit 2: Carbon Now"
                unitNumber="2.4"
                studentPageHeaderSub="Carbon Cycling"
                sectionA={ <>A Bright Idea</> }
                sectionALink="/unit-2/2.4-carbon-cycling/#a-bright-idea"
                sectionB={ <>Modeling Carbon Cycling</> }
                sectionBLink="/unit-2/2.4-carbon-cycling/#modeling-carbon-cycling"
                sectionC={ <>Carbon, Then and Now</> }
                sectionCLink="/unit-2/2.4-carbon-cycling/#carbon-then-and-now"
                sectionD={ <>Who Left The Lights On?!</> }
                sectionDLink="/unit-2/2.4-carbon-cycling/#who-left-the-lights-on"
                sectionE={ <>Reflect and Connect</> }
                sectionELink="/unit-2/2.4-carbon-cycling/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What are some ways to understand how humans connect to Earth's carbon cycle?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image29.childImageSharp.fluid}
                          alt="Traffic"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="verticalImageRight ml-2 img-fluid d-none d-md-block"
                      fluid={props.data.image29.childImageSharp.fluid}
                      alt="Traffic"
                    />

                    <p>
                      In Unit 2, you have explored photosynthesis and respiration. These processes have been part of the carbon cycle for millions of years. But now, humans like you are involved. In Lesson 2.3, you saw the human effect of the CO<sub>2</sub> record from Mauna Loa. In this lesson, you will investigate more how humans have become part of the carbon cycle.
                    </p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>
                      One way humans are part of the carbon cycle is by using fossil fuels. These are used to generate the electrical energy that you need and use each day. At the same time, using fossil fuels also relates to climate. In this lesson, you will see that it is not hard to make decisions for how to use a little less energy. In particular, you will learn that:
                    </p>
                    <ul>
                      <li>Fossil fuels consist of carbon from the past that has been stored in the geosphere and is used by humans.</li>
                      <li>Forests and oceans can take up only some of the fossil carbon (about 50 percent) that is added back into the Earth system by humans.</li>
                      <li>Fossil fuels are a key part of generating electrical energy for use by humans.</li>
                    </ul>
                  </>
                }

                paragraphThree={
                  <>
                    <p>Use the focus question above to guide your work through this lesson.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="a-bright-idea" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">A Bright Idea</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        The electrical energy at your house or at your school probably comes from fossil fuels. But how much electrical energy do you use? This connects to your use of fossil fuels. Explore this in the following activity. It will help you make connections among you, your community, energy use, carbon, and climate.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image30.childImageSharp.fluid}
                            alt="Electrical energy"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image30.childImageSharp.fluid}
                        alt="Electrical energy"
                      />

                      <ol>
                        <li>Your teacher will show you a common object. What words are written on it? What do words such as "40 watts" mean to you?</li>
                        <li>From your experience, you have an idea of how much light a 40-watt bulb emits. If the bulb was not labeled correctly, can you think of a way to measure its energy use?</li>
                        <li>
                          Your teacher will show you a device to measure energy use. Complete the following steps to compare the energy use of a 40-watt bulb with other appliances in your classroom.
                          <ol type="a">
                            <li>List in your notebook appliances that are plugged into a wall, cord, or power strip in your classroom. These might be printers, computers, monitors, projectors, or lights.</li>
                            <li>When they are on, do they use more or less electric energy than a 40-watt bulb? As a class, also discuss which appliances might use the most (or least) amount of energy.</li>
                            <li>Choose one or two of these appliances and measure how many watts it uses. Record your data.</li>
                          </ol>
                        </li>
                      </ol>

                      <p>
                        You can measure the energy use of other appliances in your school or home. What are you curious to measure? How much energy do you think it takes to recharge a cell phone? You'll be able to test a variety of appliances to answer these questions.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="modeling-carbon-cycling" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Modeling Carbon Cycling</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Earlier in this unit, you considered the idea that planting trees could remove some of the carbon that humans have added to atmosphere. That is tougher when respiration is also happening and the number of trees on Earth is decreasing.
                      </p>

                      <p>
                        Scientists also know that a lot of carbon is stored in the oceans. In Unit 1, you used a model of carbon moving into and out of water. Could some carbon that humans add to the atmosphere be stored in the oceans? You will test this idea in this section.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image31.childImageSharp.fluid}
                            alt="Candle experiment"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder mb-3"
                        fluid={props.data.image31.childImageSharp.fluid}
                        alt="Candle experiment"
                      />

                      <ol start="4">
                        <li>
                          Your teacher will show you the setup for an experiment like the one above. Complete the following steps as a class.
                          <ol type="a">
                            <li>Get water that has BTB.</li>
                            <li>Place each of two dishes on a white piece of paper. Pour the BTB water to a depth of about 0.5 cm in each.</li>
                            <li>Gently place four candles one of the dishes and light the candles.</li>
                            <li>Place a lid on the glass container, but do not let the flames go out. As they dim, gently slide the lid sideways to let in oxygen.</li>
                            <li>Carefully observe the color of the water in each dish. Record your observations.</li>
                            <li>
                              What substance do you think is causing an effect in the dish with candles? <br />
                              <em>Hint: If you need a reminder, see <Link to="/unit-1/1.1-carbon-fizz/#carbon-indicators">Carbon Indicators&mdash;Responding to Reactions</Link>.</em>
                            </li>
                          </ol>
                        </li>
                        <li>Draw your experimental setup. This drawing should take up half a page in your notebook. Make sure to label the BTB water, candles, glass dishes, and lid, and which is experimental versus control. Use colored pencils to show the BTB water and any changes you observe.</li>
                        <li>
                          Read the following to learn more about burning candles: <br />
                          Look at a burning candle. Most of the light and thermal energy is from the combustion of the wax. But what is wax, and what are its carbon connections? Wax is a molecule called a <strong>hydrocarbon</strong>. These consist largely of the elements hydrogen and carbon. In fact, a hydrocarbon may have helped get you to school today if your transportation used gasoline. Many hydrocarbons have a general formula C<sub>n</sub>H<sub>(2n+2)</sub>, with n being a chain of 20 to 30 carbon atoms. For example, one molecule in wax is C<sub>20</sub>H<sub>42</sub>. Octane in gasoline is C<sub>8</sub>H<sub>18</sub>. In the model you used, burning wax was the type of hydrocarbon. When hydrocarbons burn, CO<sub>2</sub>, H<sub>2</sub>O, and energy are released.
                        </li>
                        <li>Return to the drawing of the experiment you did. From the reading above, add labels for the different forms of carbon, even if you cannot see them.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-then-and-now" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Carbon, Then and Now</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        You have studied the flow of carbon dioxide due to respiration and photosynthesis by living things. Those organisms are on land and in the ocean. These processes were shown in a box diagram at the end of Lesson 2.1. From Mauna Loa data, you saw how a human influence led to a steady rise in CO<sub>2</sub>.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ co2Mauna26 }
                            alt="Carbon dioxide, Mauna Loa"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ co2Mauna26 }
                        alt="Carbon dioxide, Mauna Loa"
                      />

                      <p>
                        Continue with the steps below to learn how to represent the human influence in the box diagram (box model).
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image32.childImageSharp.fluid}
                            alt="Carbon cycle model before 1850"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid imageBorder mb-3 d-none d-md-block"
                        fluid={props.data.image32.childImageSharp.fluid}
                        alt="Carbon cycle model before 1850"
                      />

                      <ol start="8">
                        <li>
                          Look at the box model for the carbon cycle. It shows a time before the Industrial Revolution (&sim;1850). Discuss these questions with a partner:
                          <ol type="a">
                            <li>Which box in the diagram stores carbon in Earth?</li>
                            <li>Which arrows on the box model are affected by respiration? Which arrows are affected by photosynthesis?</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image34.childImageSharp.fluid}
                            alt="Carbon dioxide concentration, last 1000 years chart"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        fluid={props.data.image34.childImageSharp.fluid}
                        alt="Carbon dioxide concentration, last 1000 years chart"
                      />

                      <ol start="9">
                        <li>
                          Look at the Carbon Dioxide graph. It shows how CO<sub>2</sub> has changed over the last 1,000 years. It combines data from Mauna Loa and Antarctica. Discuss the questions below with your class or a partner.
                          <ol>
                            <li>For about 800 years, atmospheric CO<sub>2</sub> did not change much. At about what year did CO<sub>2</sub> start to increase?</li>
                            <li>Look again at the box model above. The box model applies to the period before about 1850. The model needs revision for periods after about 1850. From which reservoir did the extra atmospheric carbon dioxide come? Why do you think this?</li>
                            <li>Discuss with your partner how you would revise the box model to show carbon moving among reservoirs in modern times.</li>
                          </ol>
                        </li>

                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3 mt-3 imageBorder"
                              fluid={props.data.image35.childImageSharp.fluid}
                              alt="Modern box model (1870-Today)"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="largeImageCenter img-fluid d-none d-md-block imageBorder mt-3 mb-3"
                          fluid={props.data.image35.childImageSharp.fluid}
                          alt="Modern box model (1870-Today)"
                        />

                        <li>
                          During the industrial revolution, coal became commonly used in industry and transportation. Compare the box model in step 8 with a box model for <Link to="/unit-2/2.4-carbon-cycling/#modern-box">modern times</Link>. Complete these questions with a partner:
                          <ol type="a">
                            <li>How are the models similar or different?</li>
                            <li>Why do you think the arrow from "fossil carbon" to "atmosphere" is one way?</li>
                            <li>Why do you think that the arrow from "fossil carbon" goes only to "atmosphere," and not to "land" or to "ocean"?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Reservoirs, Where Carbon Resides</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Reservoirs, Where Carbon Resides</p>
                      <p>
                        Carbon is in all of Earth's systems. In science, you may have studied how carbon is part of living matter (<strong>biotic</strong>). But carbon is a part of non-living matter (<strong>abiotic</strong>) as well. You have seen how carbon as CO<sub>2</sub> is in the atmosphere. The oceans also hold carbon in many forms; that carbon moves among the <strong>oceans</strong>, <strong>atmosphere</strong>, and <strong>land</strong>. These connections with the carbon cycle will help you better understand climate science.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={earthActive33 }
                            alt="Earth's active carbon"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid  d-none d-md-block mb-3"
                        src={earthActive33 }
                        alt="Earth's active carbon"
                      />

                      <p>
                        Places on Earth with lots of carbon are called <strong>carbon reservoirs</strong>. They are like reservoirs that hold water, except that carbon reservoirs hold carbon. Because carbon can move among reservoirs, it is called "active" carbon. The middle and deep oceans are the largest reservoirs. In these places, the stored carbon is slow to mix and interact with the atmosphere.
                      </p>

                      <p>
                        Five other important reservoirs have carbon that moves at a much faster rate. They are <strong>soils</strong>, <strong>surface ocean waters</strong>, <strong>vegetation</strong> (land plants), and the <strong>atmosphere</strong>. Surface waters of the ocean are the upper several hundred meters of water. This includes the photic zone. These parts of the ocean have abiotic carbon, as well as most of the <strong>marine life</strong> in <strong>marine food webs</strong>. Land carbon consists of all the carbon in vegetation and soils.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: What Are Fossil Fuels?</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">What Are Fossil Fuels?</p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ swamp36 }
                            alt="Swamp"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight ml-2 img-fluid d-none d-md-block"
                        src={ swamp36 }
                        alt="Swamp"
                      />

                      <p>
                        You may not think about it often, but you benefit from <strong>fossil fuels</strong> every day. The common fossil fuels are <strong>oil</strong>, <strong>natural gas</strong>, and <strong>coal</strong>. This carbon has been stored in Earth for millions of years. Humans figured out how to recover those fossil fuels from the ground and use their energy. You, as part of our modern world, rely on fossil fuels. When you use fossil fuels, you also release CO<sub>2</sub>. Part of the answer to "What is a fossil fuel?" is in the word "fossil." Maybe you have heard that fossil fuels came from dinosaurs; however, most of the carbon in fossil fuels came from primary producers (photosynthesizing organisms), and not consumers (such as dinosaurs). Many of the fossil fuels we use today were actually formed <em>before</em> dinosaurs walked the Earth. For example, large reserves of coal in the eastern United States formed much before dinosaurs existed. Other coal deposits are younger than dinosaurs.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ peat37 }
                            alt="Peat"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageLeft img-fluid d d-none d-md-block mb-3 mr-3"
                        src={ peat37 }
                        alt="Peat"
                      />

                      <p>
                        Two key types of fossil fuels are coal and oil. <strong>Coal</strong> was formed in swamps and wetlands millions of years ago. As plants growing in these areas died, they accumulated at the bottom of the swamp, and were submerged under water. Bacteria partially decomposed these dead plants. The decomposition process lowered the pH and resulted in anaerobic conditions (low oxygen). This killed the bacteria that decomposed the plant material. Under the right conditions, the partially decomposed plants became <strong>peat</strong>. People can burn peat for energy. Some peat was buried even more deeply, under layers of sediment. The pressure and heat inside the Earth transformed peat into coal. Through this long process, carbon from the ancient swamp plants is now stored in the carbon of the coal. Just think: The carbon in that coal was originally removed from ancient atmosphere by photosynthesis long ago. Your modern fossil fuels were a part of ancient carbon cycling.
                      </p>

                      <p>
                        In contrast, most of the <strong>crude oil</strong> you use came from tiny primary producers in shallow seas, not large plants on land. A drop of seawater can contains more than a million of these tiny organisms. These organisms took CO<sub>2</sub> from the water and synthesized tissues for their bodies. When they died, these tiny organisms, and other organic material from the top of the ocean, settled down to the bottom of the ocean.
                      </p>

                      <p>
                        At certain times, so much material rained down to the ocean depths that decomposers could not keep up. Additionally, the decomposers at the seafloor used up the oxygen; so, some living matter did not fully decay. This organic matter mixed with the sediments and was then covered by more and more sediments. Over millions of years, the pressure and heat from the Earth combined to cook this once-living material into oil. Through these processes, the carbon of tiny ancient ocean organisms is now stored in the carbon of oil.
                      </p>

                      <p>
                        These deposits provide different kinds of hydrocarbons. In forming coal and oil, molecules made of carbon, hydrogen, oxygen, and few other atoms are changed into molecules containing mostly carbon and hydrogen. This chemical make-up means that these molecules store a lot of energy, along with a lot of carbon. Some common examples of hydrocarbons are show below.
                      </p>

                      <Row>
                        <Col md={{ span: 4, offset: 2 }}>
                          <div className="image-wrapper-xs">
                            <img
                              className="image image-bordered"
                              src={ methane38 }
                              alt="Methane"
                            />
                            <div className="image-caption image-caption-bordered">
                              Methane
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="inline-image-wrapper">
                            <img
                              className="image image-bordered"
                              src={ octane39 }
                              alt="Methane"
                            />
                            <div className="image-caption image-caption-bordered">
                              Octane
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="inline-image-wrapper">
                            <img
                              className="image image-bordered"
                              src={ paraffin40 }
                              alt="Paraffin"
                            />
                            <div className="image-caption image-caption-bordered">
                              Paraffin
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="who-left-the-lights-on" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Who Left the Lights On?!</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image41.childImageSharp.fluid}
                            alt="Light on"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image41.childImageSharp.fluid}
                        alt="Light on"
                      />

                      <p>
                        You have seen it. At home, school, and work, someone leaves lights on when everyone has left the room. At home, you might get in trouble for doing this. Lights use electricity, and generating electricity at power plants usually requires burning coal or other hydrocarbons. You also have evidence from the candle investigation that burning hydrocarbons emits CO<sub>2</sub>.
                      </p>

                      <p>
                        Complete the following steps to explore further how hydrocarbons relate to the electrical energy you use.
                      </p>

                      <ol start="11">
                        <li>
                          Join with a partner to discuss these questions. Write your ideas in your notebook.
                          <ol type="a">
                            <li>How might increasing energy use connect with increasing CO<sub>2</sub> in the atmosphere?</li>
                            <li>What are two actions you can take to reduce your fossil fuel use?</li>
                            <li>How could you use an Energy Use Monitor to measure how much less energy you are using?</li>
                            <li>Be prepared to share your ideas with other students in your class. You might even have some new ideas for your family.</li>
                          </ol>
                        </li>
                        <li>
                          Look at the graphic below. It shows the sources and uses of energy in the United States in 2005. Use the graphic to answer the following questions.
                          <ol type="a">
                            <li>What portion of the total energy supply is for residential (household) uses?</li>
                            <li>What inputs to the energy supply are fossil fuels that emit high amounts of CO<sub>2</sub>? What percent of the total are they?</li>
                            <li>What portion of the total energy supply is for transportation?</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3 imageBorder"
                            src={ energySupply42 }
                            alt="Energy supply for the United States in 2005"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                        src={ energySupply42 }
                        alt="Energy supply for the United States in 2005"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        E. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Use the following questions to help you make connections between some models that you used in this lesson, the carbon cycle, and energy use. Listen to your teacher for instructions; he or she may decide to do this as a whole class discussion or activity.
                      </p>

                      <ol start="13">
                        <li>
                          Complete these questions:
                          <ol type="a">
                            <li>The wax in candles is a hydrocarbon, which you burned when you lit the candle. Name three areas of your life that involve burning hydrocarbons.</li>
                            <li>Two main gases were released from the burning candles. What were they? Did you see any evidence for them?</li>
                            <li>You saw the water in the closed container change color. What could that represent in the real world? What is another lesson in <em>Carbon Connections</em> where you modeled something similar?</li>
                            <li>What do you think would happen to your candle model if you put algae in the water, and the algae had a source of nutrients for growth?</li>
                            <li>Imagine that the rate decreased for carbon uptake in the water. What would happen to the CO<sub>2</sub> levels in the air above the water?</li>
                          </ol>
                        </li>
                        <li>Return to the box model for modern times. How many reservoirs and arrows in the model were represented by the candle experiment? Show them in their own diagram, and add labels that help show carbon cycling in the experiment.</li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image35.childImageSharp.fluid}
                            alt="Modern box model (1870-Today)"
                          />
                        </Col>
                      </Row>
                      <Img
                        id="modern-box"
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                        fluid={props.data.image35.childImageSharp.fluid}
                        alt="Modern box model (1870-Today)"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="2.5: Earth Takes a Breath"
                NextLink="/unit-2/2.5-earth-takes-a-breath/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonCycling24

export const query = graphql`
  query {
    image29: file(relativePath: { eq: "student-pages/unit-2/29_traffic.jpg" }) {
      ...rowImage
    }
    image30: file(relativePath: { eq: "student-pages/unit-2/30_bright_idea.png" }) {
      ...rowImage
    }
    image31: file(relativePath: { eq: "student-pages/unit-2/31_candle_experiment.png" }) {
      ...rowImage
    }
    image32: file(relativePath: { eq: "student-pages/unit-2/32_boxmodel_carboncycle.png" }) {
      ...rowImage
    }
    image34: file(relativePath: { eq: "student-pages/unit-2/34_co2_concentrations_1000_years.png" }) {
      ...rowImage
    }
    image35: file(relativePath: { eq: "student-pages/unit-2/35_modern_box_model.png" }) {
      ...rowImage
    }
    image41: file(relativePath: { eq: "student-pages/unit-2/41_light_on.jpg" }) {
      ...rowImage
    }
  }
`
