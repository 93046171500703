import React from 'react'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'


export default function ButtonNext({ ButtonText, NextLink }) {
  return (
    <Row>
      <Col>
        <p className="text-right">
          <Link className="headerWhite buttonRight" to={ NextLink }>
            <Button
              className="btn-primary"
              size="lg"
            >
                Next: { ButtonText }&nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
            </Button>
          </Link>
        </p>
      </Col>
    </Row>
  )
}
