import React from 'react'
//import { Link } from 'gatsby'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export default function ContentHeader({ icon, iconAlt, studentPageHeader, unitNumber, studentPageHeaderSub, sectionA, sectionALink, sectionB, sectionBLink, sectionC, sectionCLink, sectionD, sectionDLink, sectionE, sectionELink, sectionF, sectionFLink }) {
  return (
    <div className="grid-row">
      <Row>
        <Col>
          <div className="d-flex">
            <div className="mr-3">
              <img
                src={ icon }
                alt={ iconAlt }
                className="img-fluid unitIcon"
              />
            </div>
            <div>
              <h1 style={{ lineHeight: '1' }} className="headerFontBlue">{ studentPageHeader }</h1>
              <p className="lead">
                { unitNumber }:&nbsp;
                <span className="headerBlue">
                  <strong>{ studentPageHeaderSub }</strong>
                </span>
              </p>
            </div>

            {/* <div className="three">
              <ol type="A">
                {
                  sectionA
                  ?
                  <li>
                    <Link to={ sectionALink }>{ sectionA }</Link>
                  </li>
                  :
                  <></>
                }
                {
                  sectionB
                  ?
                  <li>
                    <Link to={ sectionBLink }>{ sectionB }</Link>
                  </li>
                  :
                  <></>
                }
                {
                  sectionC
                  ?
                  <li>
                    <Link to={ sectionCLink }>{ sectionC }</Link>
                  </li>
                  :
                  <></>
                }
                {
                  sectionD
                  ?
                  <li>
                    <Link to={ sectionDLink }>{ sectionD }</Link>
                  </li>
                  :
                  <></>
                }
                {
                  sectionE
                  ?
                  <li>
                    <Link to={ sectionELink }>{ sectionE }</Link>
                  </li>
                  :
                  <></>
                }
                {
                  sectionF
                  ?
                  <li>
                    <Link to={ sectionFLink }>{ sectionF }</Link>
                  </li>
                  :
                  <></>
                }
              </ol>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  )
}
